export const MenuFooterTwo =[
    {
    title: "Creative Design",
    url: "/Creative-Design",
    cName:"footer-linkstwo",
    icon:""
},
{
    title: "Web Development",
    url: "/Web-Development",
    cName:"footer-linkstwo",
    icon:""
},
{
title: "Digital Marketing",
url: "/Digital-Marketing",
cName:"footer-linkstwo",
icon:""
},
    {
    title: "App Development",
    url: "/App-Development",
    cName:"footer-linkstwo",
    icon:""
},
    {
    title: "Video Animation",
    url: "/Video-Animation",
    cName:"footer-linkstwo",
    icon:""
},
    {
    title: "Content Writing",
    url: "/Content-Writing",
    cName:"footer-linkstwo",
    icon:""
},
    {
    title: "Cyber Security",
    url: "/Cyber-Security",
    cName:"footer-linkstwo",
    icon:""
},

]